<template>
  <base-section id="theme-features">
    <base-section-heading title="what makes us Different">Our loans approach is customized and BPO focused</base-section-heading>

    <v-container>
      <v-row>
        <v-col v-for="(feature, i) in features" :key="i" cols="12" md="6">
          <base-avatar-card
            v-bind="feature"
            align="left"
            horizontal
          >{{ feature.content }}</base-avatar-card>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionThemeFeatures',

    data: () => ({
      features: [
        {
          title: 'SAME DAY REFINANCE',
          icon: 'mdi-currency-usd',
          content: `This service allows customers to consistently stay on top of their monthly expenses. Once your loan is completely paid, you can get a new loan on the same day`,
        },
        {
          title: 'BPO FOCUSED',
          icon: 'mdi-phone',
          content: `Not only are we BPO focused in our financing but we also provide part time employment to our Premium clients in the BPO industry`,
        },
        {
          title: 'INTEREST RATE AS LOW AS 0.6%',
          icon: 'mdi-percent-outline',
          content: 'Make use of our extremely low interest rate of 0.6%/day for your term loans.',
        },
        {
          title: 'WHAT SERVICES WE OFFER',
          icon: 'mdi-emoticon-happy-outline',
          content: 'We offer loans to qualified BPO staff who are able to pay back at the agreed time.',
        },
      ],
    }),
  }
</script>
